<template>
    <div>
        <b-row v-if="isEdit">
            <b-col md="6">
                <b-form-group
                    :label="i18nFile"
                    class="label-pdv"
                    label-for="upload-file"
                    :invalid-feedback="getI18n('ERROR_CODES', 'invalid_file')"
                >
                    <b-form-file
                        id="upload-file"
                        v-model="$v.workLeaveFile.selectedFile.$model"
                        required
                        type="file"
                        accept=".jpg, .png, .jpeg, .pdf"
                        :browse-text="getI18n('COMMON', 'browse')"
                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                        :state="validateField('selectedFile', 'workLeaveFile')"
                        :disabled="uploadLoading"
                        @blur="$v.workLeaveFile.selectedFile.$touch"
                    />
                </b-form-group>
            </b-col>
            <b-col md="4" class="mt-1 align-self-center">
                <upload-button
                    :busy="uploadLoading"
                    :disabled="deleteLoading"
                    @onClick="handleUpload"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-form-group
                    :label="i18nFiles"
                    class="label-pdv"
                >
                    <div class="table-responsive table-pdv">
                        <b-table
                            hover
                            :fields="fileChildrenActionFields"
                            :items="workLeaveModal.files"
                            show-empty
                            sticky-header
                        >
                            <template v-slot:empty>
                                <div align="center">
                                <p>{{ getI18n('PAGINATION', 'no_records') }}</p>
                                </div>
                            </template>
                            <template v-slot:cell(file)="row">
                                <a v-if="row.item.url"
                                    :href="row.item.url"
                                    target="_blank">
                                    <i class="fe-eye"/>
                                </a>
                                <i v-else class="fe-eye-off" />
                            </template>
                            <template v-slot:cell(actions)="row">
                                <div class="text-right">
                                    <delete-table-button
                                        v-if="isEdit"
                                        :disabled="uploadLoading || deleteLoading"
                                        @onClick="handleDelete(row.item.id)"
                                    />
                                </div>
                            </template>
                        </b-table>
                    </div>
                    <div class="text-right mr-1">
                        <label class="label-pdv">
                            {{ footerTotalizer }}
                        </label>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import FilesService from '@src/services/FilesService'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'
import UploadButton from '@/src/components/UploadButton.vue'
import vuetableFormatters from '@/src/mixins/vuetableFormatters'
import fileChildrenActionFields from '@src/fields/childrens/fileChildrenActionFields'

const i18nCommon = 'COMMON'
const i18nKey = 'WORK_LEAVES'

const isFileValid = (value, model) => {
    if (!value || !value.type) {
        return true
    }

    if (value.type.startsWith('image') || value.type === 'application/pdf') {
        return true
    }

    return false
}

export default {
    components: {
        DeleteTableButton,
        UploadButton
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation,
        vuetableFormatters,
        fileChildrenActionFields,
    ],
    props: {
        isEdit: {
            type: Boolean,
            required: true,
        },
        modal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            uploadLoading: false,
            deleteLoading: false,
            workLeaveFile: {},
        }
    },
    validations: {
        workLeaveFile: {
            selectedFile: {
                isFileValid,
            },
        },
    },
    computed: {
        i18nFile() {
            return this.getI18n(i18nCommon, 'file')
        },
        i18nFiles() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'file',
                modifier: 2,
            })
        },
        i18nNumberOfRecords() {
            return (
                this.getI18n('PAGINATION.total') +
                ' ' +
                this.getI18n('PAGINATION.of') +
                ' ' +
                this.i18nFiles +
                ':'
            )
        },
        footerTotalizer() {
            return `${this.i18nNumberOfRecords} ${this.padLeft(this.workLeaveModal.files.length)}`
        },
        workLeaveModal: {
            get() {
                return this.modal
            },
            set(value) {
                this.$emit('update:modal', value)
            },
        },
    },
    methods: {
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        async handleUpload() {
            this.$v.workLeaveFile.$touch()
            if (this.$v.workLeaveFile.$invalid || this.uploadLoading) {
                return
            }

            if (!this.workLeaveFile.selectedFile) {
                this.warningFeedback(this.i18nFile, this.getI18n('COMMON.no_file_chosen'))
                return
            }

            this.uploadLoading = true

            const responseImg = await FilesService.uploadFile(
                    this.workLeaveFile.selectedFile,
                    'work_leave_files'
                )
                .then((responseImg) => responseImg)
                .catch((error) => {
                    this.warningFeedbackApi(this.i18nFile, error.data.errors)
                    this.uploadLoading = false
                })

            const statusCodeImg = responseImg.status.toString()
            if (statusCodeImg.charAt(0) === '2') {
                this.workLeaveModal.files.push(responseImg.data.data)
                this.workLeaveFile.selectedFile = null
                this.resetValidation()
            }

            this.uploadLoading = false
        },
        async handleDelete(id) {
            if (this.deleteLoading) {
                return
            }

            this.deleteLoading = true
            const index = this.workLeaveModal.files.findIndex(find => find.id === id);

            if (index > -1) {
                this.workLeaveModal.files.splice(index, 1);
            }
            this.deleteLoading = false
        },
    },
}
</script>
