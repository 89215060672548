<template>
    <div class="d-flex">
        <v-date-picker
            id="date-picker-single"
            ref="date-picker-single"
            v-model="valueProp"
            :class="[childClass, disabled ? 'flex-grow-1 input-date-disabled' : 'flex-grow-1 input-date', disableCleanButton ? 'clear-date-disabled' : '']"
            :locale="$i18n.locale.toLowerCase()"
            :columns="$screens({ default: 1 })"
            :max-date="maxDate"
            :min-date="minDate"
            :popover="{ placement: 'top', visibility: disabled ? 'hidden' : 'focus' }"
            :is-required="disableCleanButton"
        >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="d-flex justify-content-center">
                    <b-form-input
                        class="input-pdv-blue"
                        :value="inputValue"
                        :disabled="disabled"
                        v-on="inputEvents"
                    />
                    <button
                        v-if="!disableCleanButton"
                        class="btn btn-clean"
                        type="button"
                        :disabled="!valueProp || disabled"
                        @click="valueProp = null">
                        {{ getI18n('COMMON', 'clean')}}
                    </button>
                </div>
            </template>
        </v-date-picker>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Date,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        childClass: {
            type: String,
            default: '',
        },
        maxDate: {
            type: Date,
            required: false,
            default: null
        },
        minDate: {
            type: Date,
            required: false,
            default: null
        },
        disableCleanButton: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        valueProp: {
            get() { return this.$props.value; },
            set(value) { this.$emit('update:value', value); },
        },
    },
}
</script>
