<template>
    <b-button
        block
        :class="[childClass, 'btn btn-pdv-gradient-green']"
        :disabled="busy || disabled"
        @click="$emit('onClick')"
    >
        <template v-if="busy">
            <b-spinner small></b-spinner>
        </template>
        <template v-else>
            <i class="fe-upload mr-1"></i>
            {{ getI18n('COMMON.upload_file') }}
        </template>
    </b-button>
</template>

<script>
export default {
    props: {
        childClass: {
            type: String,
            required: false,
            default: ''
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>
