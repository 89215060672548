<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="lg"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nWorkLeave"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nWorkLeave"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'WORKLEAVE')"
                                :title="i18nWorkLeave"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'WORKLEAVE')"
                                :title="i18nWorkLeave"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="work-leave-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nUser"
                                    label-for="modal-user"
                                    class="required label-pdv" >
                                    <multiSelectWithService
                                        :id="'modal-user'"
                                        ref="userMultiselect"
                                        v-model="workLeaveModal.user"
                                        :input-function="$v.workLeaveModal.user.id.$touch"
                                        :select-class="validationClass($v.workLeaveModal.user.id)"
                                        :service="'users'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isWorkLeaveCreated || submitLoading"
                                        :parameters="usersParameters"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n('COMMON', 'start_date')"
                                    class="required label-pdv"
                                    label-for="modal-start-date"
                                    :invalid-feedback="i18nInvalidDate"
                                >
                                    <date-picker
                                        id="modal-start-date"
                                        :child-class="validationClass($v.workLeaveModal.start_at)"
                                        :value.sync="$v.workLeaveModal.start_at.$model"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n('COMMON', 'end_date')"
                                    class="required label-pdv"
                                    label-for="modal-end-date"
                                    :invalid-feedback="i18nInvalidDate"
                                >
                                    <date-picker
                                        id="modal-end-date"
                                        :child-class="validationClass($v.workLeaveModal.end_at)"
                                        :value.sync="$v.workLeaveModal.end_at.$model"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <b-form-group
                                    :label="i18nReason"
                                    class="required label-pdv"
                                    label-for="modal-name"
                                    :invalid-feedback="i18nInvalidReason"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.workLeaveModal.reason.$model"
                                        class="input-pdv-blue"
                                        name="reason"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        autocomplete="off"
                                        :state="validateField('reason', 'workLeaveModal')"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.workLeaveModal.reason.$touch"
                                        @input="clearResponseError('reason', 'workLeaveModal')"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab
                        :title="i18nFile"
                    >
                        <work-leave-files
                            :is-edit="isEdit"
                            :modal="workLeaveModal"
                        />
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex !== 1"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex++"
                />
                <save-button
                    v-else-if="isWorkLeaveCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, requiredIf, minValue, minLength } from 'vuelidate/lib/validators'
import WorkLeaveService from '@src/services/WorkLeaveService'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import workLeaveFiles from '@src/router/views/workLeaves/workLeaveFiles.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import DatePicker from '@/src/components/DatePicker'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'
const i18nKey = 'WORK_LEAVES'

export default {
    components: {
        workLeaveFiles,
        multiSelectWithService,
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        DatePicker,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    user_id: 0,
                    user: {
                        id: 0,
                        name: '',
                    },
                    start_at: null,
                    end_at: null,
                    reason: '',
                    files: [],
                }
            },
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            workLeaveModal: this.modal,
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            tabIndex: 0,
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {
        workLeaveModal: {
            user: {
                id: {
                    required,
                    minValue: minValue(1),
                },
            },
            start_at: {
                required,
                beforeEndDate(value, model) {
                    return value <= model.end_at
                },
            },
            end_at: {
                required,
                afterStartDate(value, model) {
                    return value >= model.start_at
                },
            },
            reason: {
                required: requiredIf(function () {
                    return !this.isApprove
                }),
                minLength: minLength(3),
            },
        },
    },
    computed: {
        isWorkLeaveCreated() {
            return this.workLeaveModal.id > 0
        },
        i18nTitleModal() {
            if (this.isWorkLeaveCreated) {
                return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nWorkLeave}`
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_work_leave')
            }
        },
        i18nReason() {
            return this.getI18n(i18nCommon, 'reason')
        },
        i18nUser() {
            return this.getI18n(i18nCommon, 'collaborator')
        },
        i18nInvalidDate() {
            return this.getI18n('ERROR_CODES', 'invalid_date')
        },
        i18nInvalidReason() {
            return this.getI18n('ERROR_CODES', 'invalid_reason')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'info',
                modifier: 2,
            })
        },
        i18nFile() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'file',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isWorkLeaveCreated ? 'edited' : 'created'
        },
        i18nWorkLeave() {
            return this.getI18n(i18nKey, 'TITLES.work_leave')
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.workLeaveModal.$touch()
                    if (this.$v.workLeaveModal.$invalid) {
                        event.preventDefault();
                    }
                }
            }
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.tabIndex = 0
            this.submitLoading = false
            this.deleteLoading = false

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.workLeaveModal = Object.assign(
                this.workLeaveModal,
                {
                    id: 0,
                    user_id: 0,
                    user: {
                        id: 0,
                        name: '',
                    },
                    start_at: null,
                    end_at: null,
                    reason: '',
                    files: [],
                }
            )
            this.$v.workLeaveModal.$reset()
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.workLeaveModal.$touch()
            if (!this.$v.workLeaveModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const modalInfo = {
                id: this.workLeaveModal.id,
                user_id: this.workLeaveModal.user.id,
                start_at: moment(this.workLeaveModal.start_at).format('YYYY-MM-DD'),
                end_at: moment(this.workLeaveModal.end_at).format('YYYY-MM-DD'),
                reason: this.workLeaveModal.reason,
                files: this.workLeaveModal.files,
            }

            const operation = this.isWorkLeaveCreated
                ? WorkLeaveService.update(modalInfo.id, modalInfo)
                : WorkLeaveService.create(modalInfo)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.resetValidation()
                this.$emit('refresh')
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkLeave, this.modalOperation, 0)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteWorkLeave)
        },
        async deleteWorkLeave(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.$v.workLeaveModal.$touch()
            this.deleteLoading = true
            const response = await WorkLeaveService.delete(this.workLeaveModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nWorkLeave, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
    },
}
</script>
