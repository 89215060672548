<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main'
import pagination from '@src/mixins/pagination'
import FilterBar from '@src/components/FilterBar'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import WorkLeaveService from '@src/services/WorkLeaveService'
import workLeaveModal from '@/src/router/views/workLeaves/workLeaveModal.vue'
import workLeavesFilterModal from '@views/workLeaves/workLeavesFilterModal'
import GridFormModal from '@src/components/GridFormModal.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import fileChildrenActionFields from '@src/fields/childrens/fileChildrenActionFields'
import moment from 'moment';
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'WORK_LEAVES'

export default {
    components: {
        workLeaveModal,
        workLeavesFilterModal,
        GridFormModal,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        FilterBar,
        AddLabelButton,
        CountTableButton,
        MoreTableButton,
        FilterLabelButton,
    },
    mixins: [
        pagination,
        swalFeedbackVue,
        filterVuetable,
        vuetableFormatter,
        fileChildrenActionFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'start_at',
                    direction: 'desc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            filters: {
                users: [],
            },
            modal: {
                id: 0,
                user_id: 0,
                user: {
                    id: 0,
                    name: '',
                },
                start_at: null,
                end_at: null,
                reason: '',
                files: [],
            },
            modalIsEdit: false,
            modalHasResponseError: false,
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'user.name',
                    title: this.getI18n(i18nCommon, 'collaborator'),
                    sortField: 'user|user.name',
                },
                {
                    name: 'start_at',
                    title: this.getI18n(i18nCommon, 'start_date'),
                    sortField: 'start_at',
                    formatter: this.formatDate,
                },
                {
                    name: 'end_at',
                    title: this.getI18n(i18nCommon, 'end_date'),
                    sortField: 'end_at',
                    formatter: this.formatDate,
                },
                {
                    name: 'reason',
                    title: this.getI18n(i18nCommon, 'reason'),
                },
                {
                    name: 'files',
                    title: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'file',
                        modifier: 2,
                    })
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            fieldsBusinessUnitsModal: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON.business_unit'),
                    sortable: true,
                },
            ],
            filterText: '',
            additionalParameters: {
                with: ['user', 'files'],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.work_leave',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nWorkLeaves() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.work_leave',
                modifier: 2,
            })
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewWorkLeave() {
            return this.getI18n(i18nKey, 'BUTTONS.new_work_leave')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `reason:${searchText};user.name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['user', 'files'],
                user_id: this.filters.users.map((user) => user.id)
            })
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return WorkLeaveService.fetchVuetable(url, params)
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.workLeaveModal.cleanModal())
        },
        showModal() {
            this.$nextTick(() => this.$refs.workLeaveModal.showModal())
        },

        replaceModalData(data) {
            Object.assign(this.modal, {
                ...data,
            })

            if (this.modal.start_at) {
                this.modal.start_at = moment(this.modal.start_at, 'YYYY-MM-DD').toDate();
            }

            if (this.modal.end_at) {
                this.modal.end_at = moment(this.modal.end_at, 'YYYY-MM-DD').toDate();
            }

            Object.assign(this.modal, {
                files: []
            })

            Object.assign(this.modal.files, data.files)
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.workLeavesFilterModal.showModal(this.filters)
            )
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(data) {
            this.modalIsEdit = false
            this.replaceModalData(data)
            this.showModal()
        },
        openModalFiles(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridFilesModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'WORKLEAVE')"
                            :title="i18nNewWorkLeave"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nWorkLeaves" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="files" slot-scope="props">
                            <count-table-button :value="props.rowData.files.length" @onClick="openModalFiles(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <work-leave-modal
            ref="workLeaveModal"
            :modal-is-edit="modalIsEdit"
            :modal="modal"
            @refresh="reloadTable"
        />
        <work-leaves-filter-modal
            ref="workLeavesFilterModal"
            :handle-submit="filter"
        />
        <grid-form-modal
            ref="gridFilesModal"
            :modal="modal"
            :items="modal.files"
            :fields="fileChildrenActionFields"
            :title="
                getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'file',
                        modifier: 2,
                    })
            "
        >
            <template v-slot:cell(file)="row">
                <a v-if="row.item.url"
                    :href="row.item.url"
                    target="_blank">
                    <i class="fe-eye"/>
                </a>
                <i v-else class="fe-eye-off" />
            </template>
        </grid-form-modal>
    </Layout>
</template>
