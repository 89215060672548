<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            fileChildrenActionFields: [
                {
                    key: 'original_name',
                    label: this.getI18n(i18nCommon, 'name'),
                    sortable: true,
                },
                {
                    key: 'size',
                    label: this.getI18n(i18nCommon, 'size'),
                    sortable: true,
                    formatter: this.formatKilobyte,
                },
                {
                    key: 'file',
                    label: this.getI18n(i18nCommon, 'file'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
}
</script>
